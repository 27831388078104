import React from 'react'
import Layout from '@components/Layout'

const NotFound = () => {
  return (
    <Layout lang={'en-US'}>
      <main>
        <h1
          style={{
            height: 'calc(100vh - 511px)',
            margin: '100px auto',
            textAlign: 'center',
            fontSize: '2rem',
          }}
        >
          404: Not found
        </h1>
      </main>
    </Layout>
  )
}

export default NotFound
